// Variables
@import "variables";

@import "bootstrap/bootstrap";

@import "font-awesome/font-awesome";

$font-script:		"reklame-script", sans-serif;

$z-index-10:		1000;
$z-index-9:			900;
$z-index-2:			200;
$z-index-1:			100;

html,
body { 
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

img {
	max-width: 100%;
	height: auto;
}

body {
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
}

////////////////////////////////////////////////////////
//
// General scaffolding
//
////////////////////////////////////////////////////////

.wallpaper {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	background: url("/images/bg.jpg") center center no-repeat;
	background-size: cover;
	z-index: $z-index-1;
}

	.wallpaper-mask {
		background: rgba(0,0,0,0.5);
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
		z-index: $z-index-2;
	}

.app-content {
	position: relative;
	z-index: $z-index-10;
	text-align: center;
	padding: 30px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-content: center;
	align-items: center;
}

.brand {
	width: 320px;
	margin-bottom: 27px;
	height: 75px;
	margin-top: 4px;
}

.box {
	border-radius: 6px;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.1);
	margin: 0 auto;
	position: relative;
	width: 380px;
	background: white;
	overflow: hidden;
	max-width: 100%;
}

.app-message {
	width: 260px;
	margin: 35px 0 0;
	color: white;
}

////////////////////////////////////////////////////////
//
// Typography
//
////////////////////////////////////////////////////////

p:last-child {
	margin: 0;
}

h1,
h2,
h3 {
	&:first-child {
		margin-top: 0;
	}
}

h2 {
	font-size: 21px;
}

h3 {
	font-weight: normal;
	font-size: 28px;
	text-align: center;
	margin: 0;
}

////////////////////////////////////////////////////////
//
// Buttons
//
////////////////////////////////////////////////////////

.form-control {
	box-shadow: none !important;
	border-top-left-radius: 0;
}

.btn-primary,
.btn-default,
.btn-info {
	border: transparent !important;
	text-transform: uppercase;
	letter-spacing: 1px; 
}

////////////////////////////////////////////////////////
//
// Panels
//
////////////////////////////////////////////////////////

.panel-heading {
	background: #585c62;
	padding: 23px 30px;
	text-align: left;
	color: white;
	font-size: 18px;
	line-height: 22px;
	
	strong,
	b {
		display: block;
		font-size: 15px;
		font-weight: 400;
		opacity: .6;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.panel-body {
	padding: 23px 30px;
	text-align: left;
	
	a {
		font-weight: bold;
		
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

	.panel-alert {
		margin: 0px -30px 0;
		padding-top: 10px;
	}
	
		.panel-alert-message {			
			padding: 13px 30px;
			background: $fruitcake-orange;
			color: white;
		}
		
	.panel-gif {
		margin: 0 auto 15px;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		background: url("/images/gif1.gif") center center no-repeat;
		background-size: cover;
	}